/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/hooks/i18n/i18n.js
 *
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const useI18n = (initialLanguage = 'en') => {
  // const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        // lng: initialLanguage,
        fallbackLng: initialLanguage,
        // debug: true,
        // loadPath: '/locales/{{lng}}/{{ns}}.json',
        addPath: 'locales/add/{{lng}}/{{ns}}',

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
          caches: ['cookie'],
          order: [
            'querystring',
            'cookie',
            'navigator',
            'htmlTag',
            'path',
            'subdomain',
          ],
        },
      });
    window.i18n = i18n;
  }, [initialLanguage]);
  return { i18n, t: i18n.t, changeLanguage: i18n.changeLanguage };
};

const TranslationContext = createContext();

const TranslationProvider = ({ children, initialLanguage = 'en' }) => (
  <TranslationContext.Provider value={useI18n(initialLanguage)}>
    {children}
  </TranslationContext.Provider>
);

TranslationProvider.propTypes = {
  children: PropTypes.node,
  initialLanguage: PropTypes.string,
};

const useTranslationContext = () => {
  return useContext(TranslationContext);
};

export { i18n, useTranslationContext, TranslationProvider, TranslationContext };
