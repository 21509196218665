/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * scopes
 * @default
 *
 */

const scopes = {
  home: true,
  requests: (googleUser) =>
    googleUser &&
    googleUser.isSignedIn() &&
    googleUser
      .getBasicProfile()
      .getEmail()
      .indexOf('@cloudpilots.com') > 0,
};

export { scopes as default };
