// import firebase from "firebase";
import firebase from 'firebase/app';
// import "firebase/auth";
// import "firebase/firestore";

const config = {
  apiKey: 'AIzaSyCCY6O_bXPNlPoPQMXk7YusNyF3EUTD_AQ',
  authDomain: 'cpl-gsuite-licensing.firebaseapp.com',
  databaseURL: 'https://cpl-gsuite-licensing.firebaseio.com',
  projectId: 'cpl-gsuite-licensing',
  storageBucket: 'cpl-gsuite-licensing.appspot.com',
  messagingSenderId: '660478209218',
  appId: '1:660478209218:web:a166f98d562c9ea8eb1f5b',
};
const app = firebase.initializeApp(config);

export { firebase as default, app };
