/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/hooks/googleAuthentication.js
 *
 */

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useGoogleLogin } from 'react-use-googlelogin';
import config from '../config/config.json';

const GoogleAuthContext = React.createContext(); // Not necessary, but recommended.

const GoogleAuthProvider = ({ children }) => {
  const googleAuth = useGoogleLogin({
    clientId: config.clientId, // Your clientID from Google.
    scope: config.scopes.join(' '),
    autoSignIn: 'none',
  });

  const { isSignedIn, googleUser } = googleAuth;
  const googleAuthInfo = useMemo(
    () => ({
      ...googleAuth,
      isLoggedIn: isSignedIn,
      accessToken: isSignedIn && googleUser.accessToken,
      scope: isSignedIn && googleUser.getAuthResponse(true).scope,
    }),
    [googleAuth, googleUser, isSignedIn],
  );

  return (
    <GoogleAuthContext.Provider value={googleAuthInfo}>
      {/* The rest of your app */}
      {children}
    </GoogleAuthContext.Provider>
  );
};

GoogleAuthProvider.propTypes = {
  children: PropTypes.node,
};

const useGoogleAuth = () => React.useContext(GoogleAuthContext);

function createGoogleUserService({
  googleUser,
  auth2,
  signIn,
  signOut,
  isLoggedIn,
  isInitialized,
  accessToken,
  scope,
}) {
  return {
    getProfile: () => (isLoggedIn ? googleUser.profileObj : {}),
    isAdmin: () => false, // we don't know this from the user info,
    isLoggedIn: () => isInitialized && isLoggedIn,
    getToken: () => isLoggedIn && googleUser.getAuthResponse(true).id_token,
    getAccessToken: () => accessToken,
    checkAuth: () => {
      signIn({ prompt: 'none' });
    },
    refreshAuth: () => {
      console.info('googleAuthenthentication refreshAuth login');
      signIn();
    },
    login: async () => {
      // user has been authenticated, don't do CABA auth
      console.info('googleAuthenthentication googleUserService login');
      signIn({ prompt: 'none' });
    },
    getScope: scope,
    getGoogleUser: () => googleUser,
    authenticate: (prompt = 'consent') => {
      // console.info('authenticate', prompt);
      signIn({ prompt });
    },
    signOut: () => signOut(),
    auth2,
    accessToken,
  };
}

function useGoogleUser() {
  const {
    googleUser,
    auth2,
    signIn,
    signOut,
    isLoggedIn,
    isInitialized,
    accessToken,
  } = useGoogleAuth();

  const googleUserService = useMemo(
    () =>
      createGoogleUserService({
        googleUser,
        auth2,
        signIn,
        signOut,
        isLoggedIn,
        isInitialized,
      }),
    [auth2, googleUser, isInitialized, isLoggedIn, signIn, signOut],
  );

  return {
    googleUserService,
    googleUser,
    auth2,
    signIn,
    signOut,
    isLoggedIn,
    isInitialized,
    accessToken,
  };
}

export { useGoogleUser, GoogleAuthProvider, useGoogleAuth };
