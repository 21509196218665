/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/hooks/swagger.js
 *
 */

import Swagger from 'swagger-client';
import { useState, useEffect } from 'react';

const useSwagger = (url, token, onlyAuthorized = false) => {
  const [client, setClient] = useState({});

  useEffect(() => {
    if (onlyAuthorized && !!token) {
      setClient({});
      (async () => {
        console.info('Initalizing API');
        const newClient = await Swagger({
          url: `${url}/swagger.json`,
          requestInterceptor: (req) => {
            /* eslint-disable no-param-reassign */
            req.headers.Authorization = `Bearer ${token}`;
            return req;
          },
        });
        setClient(newClient);
      })();
    }
  }, [onlyAuthorized, token, url]);

  return client;
};

// eslint-disable-next-line import/prefer-default-export
export { useSwagger };
