/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/hooks/firebase/token.js
 *
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import firebase, { auth, firestore } from '../../services/firebase';
import { useApi } from '../api';

const FirebaseContext = React.createContext();

const FirebaseProvider = ({ children }) => {
  const { apis, isReady } = useApi();
  const [firebaseToken, setFirebaseToken] = useState();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userCredentials, setUserCredentials] = useState();

  useEffect(() => {
    if (isReady) {
      (async () => {
        const {
          obj: { token },
        } = await apis.permissions.token();
        setFirebaseToken(token);
      })();
    }
  }, [apis, isReady]);

  useEffect(() => {
    if (firebaseToken) {
      (async () => {
        const userCreds = await auth.signInWithCustomToken(firebaseToken);
        setIsSignedIn(true);
        setUserCredentials(userCreds);
      })();
    } else {
      setIsSignedIn(false);
      setUserCredentials();
    }
  }, [firebaseToken]);

  return (
    <FirebaseContext.Provider
      value={{ isSignedIn, userCredentials, firebase, auth, firestore }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

const useFirebase = () => React.useContext(FirebaseContext);

export { FirebaseProvider, FirebaseContext, useFirebase };
