/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * theme
 * @default
 *
 */

const theme = {
  palette: {
    type: 'light', // @Feature: Dark mode "dark"
    background: {
      default: '#fff',
    },
    primary: {
      light: '#80b4ff',
      main: '#4285f4',
      dark: '#0059c1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6f81a3',
      main: '#425574',
      dark: '#162c48',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#4285f4',
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: '#4285f4',
      colorSecondary: '#0059c1',
    },
    MuiLinearProgress: {
      colorPrimary: '#4285f4',
      colorSecondary: '#0059c1',
    },
  },
  typography: {
    useNextVariants: true,
  },
};

export { theme as default };
