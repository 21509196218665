/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/hooks/api.js
 *
 */
import PropTypes from 'prop-types';
import React from 'react';
import { useSwagger } from './swagger';
import { useGoogleAuth } from './googleAuthentication';

const useApiService = () => {
  const { isLoggedIn, accessToken } = useGoogleAuth();

  const swagger = useSwagger(
    `${process.env.REACT_APP_API_URL}/api/v1`,
    accessToken,
    true,
  );
  const { apis, spec } = swagger;

  return {
    swagger,
    apis,
    spec,
    isLoaded: !!swagger,
    isAuthorized: isLoggedIn,
    isReady:
      !!swagger && isLoggedIn && !!accessToken && !!apis && !!apis.licensing,
    token: accessToken,
  };
};

const ApiContext = React.createContext();

const ApiProvider = ({ children }) => {
  const api = useApiService();
  window.Api = api;
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
  children: PropTypes.node,
};

const useApi = () => React.useContext(ApiContext);

export { useApi, ApiProvider };
