/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/components/errorBoundary.js
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = {
  error: {
    whiteSpace: 'pre-wrap',
  },
};

class ErrorBoundary extends React.Component {
  state = { hasError: false, errorInfo: undefined };

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('failed to render ', error, errorInfo);
    this.setState({ error, errorInfo, hasError: true });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    const { classes } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      let stackTrace;
      if (typeof errorInfo === 'object' && errorInfo.componentStack) {
        stackTrace = errorInfo.componentStack;
      } else {
        stackTrace = JSON.stringify(errorInfo, undefined, 2);
      }
      return (
        <>
          <h1>Something went wrong.</h1>
          <Typography>
            <b>Error: {error.toString()}</b>
          </Typography>
          <pre className={classes.error}>{stackTrace}</pre>
        </>
      );
    }

    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorBoundary);
