/* eslint-disable no-shadow */
import firebase from './firebase';
import 'firebase/firestore';

const firestore = firebase.firestore();

const settings = {
  // defaults to true in 5.8.1
  // timestampsInSnapshots: true
};
firestore.settings(settings);

const init = () => {
  const firestore = firebase.firestore();

  const settings = {
    // defaults to true in 5.8.1
    // timestampsInSnapshots: true
  };
  firestore.settings(settings);
  return firestore;
};

init();

export { firestore as default, init };
