/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * index
 * @default
 *
 */

// *** Do not modify this file, entry point core/app.js *** //

import React from 'react';
import ReactDOM from 'react-dom';
import App from './core/app';
import * as serviceWorker from './core/serviceWorker';
import './core/index.css';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
