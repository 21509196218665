/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * App
 * @default
 *
 */

// custom
import React from 'react';
import ErrorBoundary from '../components/errorBoundary';
// config
import { config, defaultItem, menu, routes, scopes } from '../config';
import { ApiProvider } from '../hooks/api';
import { FirebaseProvider } from '../hooks/firebase/token';
import { GoogleAuthProvider } from '../hooks/googleAuthentication';
import { TranslationProvider } from '../hooks/i18n/i18n';
// import Api from '../services/Api';
import './app.css';
import PageLayout from './pageLayout';

function App() {
  return (
    <ErrorBoundary>
      <TranslationProvider initialLanguage='en'>
        <GoogleAuthProvider>
          <ApiProvider>
            <FirebaseProvider>
              <PageLayout
                appName={config.appName}
                clientId={config.clientId}
                defaultItem={defaultItem}
                hasMainMenuLogoColor
                // isReady={isReady}
                menu={menu}
                // onDidMount={onAppDidMount}
                routes={routes}
                scopes={scopes}
                // noAppBar
                // noMenu
              />
            </FirebaseProvider>
          </ApiProvider>
        </GoogleAuthProvider>
      </TranslationProvider>
    </ErrorBoundary>
  );
}

export { App as default };
