/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * src/app/core/pageLayout.js
 *
 */

import App from '@cloudpilots/core/components/core/App';
import defaultTheme from '@cloudpilots/core/components/core/theme';
import { Context } from '@cloudpilots/core/components/services/Caba';
import { SnackbarProvider } from '@cloudpilots/core/components/snackbars';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import deepmerge from 'deepmerge';
import React, { useMemo } from 'react';
// config
import { config } from '../config';
import './app.css';
import customTheme from './theme';
import { useGoogleUser } from '../hooks/googleAuthentication';
import NotificationManager from '@cloudpilots/core/components/notifications/NotificationManager';
import { useApi } from '../hooks/api';
import PropTypes from 'prop-types';
// import Api from '../services/Api';

const { appName } = config;

// eslint-disable-next-line max-lines-per-function
function PageLayout({ scopes, ...props }) {
  const { theme } = useMemo(() => {
    const mergedThemes = deepmerge(defaultTheme, customTheme);
    const muiTheme = createMuiTheme(mergedThemes);
    return { theme: muiTheme, themeObj: mergedThemes };
  }, []);
  const Api = useApi();
  const { isLoaded } = Api;

  const {
    googleUserService,
    signIn,
    isInitialized,

    googleUser,
  } = useGoogleUser();

  const onLogin = () => {
    // console.info('pageLayout onLogin');
    signIn();
  };

  const onAppDidMount = async ({ context: Caba }) => {};

  const validatedScopes = useMemo(() => {
    const parsedScopes = {};
    Object.keys(scopes).forEach((scopeKey) => {
      if (typeof scopes[scopeKey] === 'function') {
        parsedScopes[scopeKey] = scopes[scopeKey](googleUser);
      } else {
        parsedScopes[scopeKey] = scopes[scopeKey];
      }
    });
    return parsedScopes;
  }, [googleUser, scopes]);

  return (
    <MuiThemeProvider theme={theme}>
      <Context.Provider value={googleUserService}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CssBaseline />

          <App
            appName={appName}
            onLogin={onLogin}
            setLogin={(args) => console.info('set login', args)}
            isReady={isLoaded && isInitialized}
            // clientId={clientId}
            notify={NotificationManager.notify}
            onDidMount={onAppDidMount}
            scopes={validatedScopes}
            {...props}
          />
        </SnackbarProvider>
      </Context.Provider>
    </MuiThemeProvider>
  );
}
PageLayout.propTypes = {
  scopes: PropTypes.object,
};

export default PageLayout;
