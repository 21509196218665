/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * routes
 * @default
 *
 */
import loadable from '@loadable/component';
// import Home from '../pages/home';
// import Requests from '../pages/requests';
// import RequestPage from '../pages/request';
// import { lazy as loadable } from 'react';

const Home = loadable(() =>
  import('../pages/home' /* webpackChunkName: "page.home" */),
);
const Requests = loadable(() =>
  import('../pages/requests' /* webpackChunkName: "page.requests" */),
);
const RequestPage = loadable(() =>
  import('../pages/request' /* webpackChunkName: "page.request" */),
);

const routes = [
  {
    id: 'home',
    handler: Home,
    path: '/',
    hasScope: true,
    exact: true,
  },
  {
    id: 'requests',
    handler: Requests,
    path: '/requests',
    hasScope: true,
    exact: true,
  },
  {
    id: 'requests',
    handler: RequestPage,
    path: '/request/:requestId',
    hasScope: true,
    exact: true,
  },
];

export { routes as default };
