/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * menu
 * @default
 *
 */

import DashboardIcon from '@material-ui/icons/ShoppingCart';
import ReceiptIcon from '@material-ui/icons/Receipt';

const menu = [
  {
    title: 'License Requests',
    icon: ReceiptIcon,
    to: '/requests',
    id: 'requests',
  },
];

const defaultItem = {
  title: 'Licenses',
  icon: DashboardIcon,
  to: '/',
  id: 'home',
};

export { menu, defaultItem };
